/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_header_16mm3_1axeh_141:not(#\9) {
  position: relative;
  margin-block: 0;
  margin-inline: 0;
  color: var(--color-text-dropdown-group-label-iy2p4t, #424650);
  border-block: var(--border-divider-list-width-27y3k5, 1px) solid transparent;
  border-inline: var(--border-divider-list-width-27y3k5, 1px) solid transparent;
  border-inline-width: 0;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding-block: calc(var(--space-xxs-p8yyaw, 4px) + var(--border-item-width-yel47s, 2px) - var(--border-divider-list-width-27y3k5, 1px));
  padding-inline: calc(var(--space-button-horizontal-8jxzea, 20px) + var(--border-item-width-yel47s, 2px));
  z-index: 1;
}
.awsui_header_16mm3_1axeh_141.awsui_disabled_16mm3_1axeh_156:not(#\9) {
  color: var(--color-text-dropdown-item-disabled-7fg5u8, #b4b4bb);
  cursor: default;
}
.awsui_header_16mm3_1axeh_141.awsui_expandable-header_16mm3_1axeh_160:not(#\9) {
  border-block-start-color: var(--color-border-dropdown-group-2xoluv, #c6c6cd);
  border-block-end-color: var(--color-border-dropdown-group-2xoluv, #c6c6cd);
  cursor: pointer;
}
.awsui_header_16mm3_1axeh_141.awsui_expandable-header_16mm3_1axeh_160.awsui_disabled_16mm3_1axeh_156:not(#\9) {
  cursor: default;
}
.awsui_header_16mm3_1axeh_141.awsui_expandable-header_16mm3_1axeh_160:not(#\9):focus {
  outline: none;
}
.awsui_header_16mm3_1axeh_141.awsui_expandable-header_16mm3_1axeh_160.awsui_rolled-down_16mm3_1axeh_171:not(#\9) {
  border-block-end-color: transparent;
}
.awsui_header_16mm3_1axeh_141.awsui_expandable-header_16mm3_1axeh_160.awsui_highlighted_16mm3_1axeh_174:not(#\9) {
  background-color: var(--color-background-dropdown-item-hover-zhzc84, #f3f3f7);
  color: var(--color-text-dropdown-item-highlighted-rnfxxj, #0f141a);
  padding-block: var(--space-xxs-p8yyaw, 4px);
  padding-inline: var(--space-button-horizontal-8jxzea, 20px);
  border-block: var(--border-item-width-yel47s, 2px) solid var(--color-border-dropdown-item-hover-4p0gsi, #8c8c94);
  border-inline: var(--border-item-width-yel47s, 2px) solid var(--color-border-dropdown-item-hover-4p0gsi, #8c8c94);
  border-start-start-radius: var(--border-radius-item-05df9h, 8px);
  border-start-end-radius: var(--border-radius-item-05df9h, 8px);
  border-end-start-radius: var(--border-radius-item-05df9h, 8px);
  border-end-end-radius: var(--border-radius-item-05df9h, 8px);
  z-index: 2;
}
.awsui_header_16mm3_1axeh_141.awsui_expandable-header_16mm3_1axeh_160.awsui_highlighted_16mm3_1axeh_174.awsui_disabled_16mm3_1axeh_156:not(#\9) {
  background-color: var(--color-background-dropdown-item-dimmed-dlm6wk, transparent);
  border-color: var(--color-border-dropdown-item-dimmed-hover-6qayyi, #8c8c94);
  color: var(--color-text-dropdown-item-dimmed-7c5xo0, #b4b4bb);
}
.awsui_header_16mm3_1axeh_141.awsui_expandable-header_16mm3_1axeh_160.awsui_highlighted_16mm3_1axeh_174.awsui_is-focused_16mm3_1axeh_192:not(#\9) {
  border-color: var(--color-border-dropdown-item-focused-5afjvu, #424650);
  box-shadow: inset 0 0 0 var(--border-control-focus-ring-shadow-spread-0ctpjf, 0px) var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_category_16mm3_1axeh_197:not(#\9) {
  list-style: none;
  margin-block-start: calc(-1 * var(--border-divider-list-width-27y3k5, 1px));
  padding-block: 0;
  padding-inline: 0;
}
.awsui_category_16mm3_1axeh_197:not(#\9):first-child {
  margin-block-start: 0;
}
.awsui_category_16mm3_1axeh_197.awsui_expandable_16mm3_1axeh_160:not(#\9) {
  border-block-start: 0;
}
.awsui_category_16mm3_1axeh_197:not(#\9):last-child {
  border-block-end: none;
}
.awsui_category_16mm3_1axeh_197.awsui_variant-navigation_16mm3_1axeh_212:not(#\9) {
  padding-block-start: var(--space-xxs-p8yyaw, 4px);
}
.awsui_category_16mm3_1axeh_197.awsui_variant-navigation_16mm3_1axeh_212.awsui_expandable_16mm3_1axeh_160:not(#\9) {
  padding-block-start: 0;
}

.awsui_expand-icon_16mm3_1axeh_219:not(#\9) {
  position: relative;
  inset-inline-start: var(--space-s-34lx8l, 12px);
  inline-size: var(--space-m-udix3p, 16px);
  display: inline-block;
  transition: transform var(--motion-duration-rotate-180-dpvl4m, 135ms) var(--motion-easing-rotate-180-e270ko, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_expand-icon_16mm3_1axeh_219:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_expand-icon_16mm3_1axeh_219:not(#\9), .awsui-mode-entering .awsui_expand-icon_16mm3_1axeh_219:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_expand-icon-up_16mm3_1axeh_236:not(#\9) {
  transform: rotate(-180deg);
}
.awsui_expand-icon-right_16mm3_1axeh_239:not(#\9) {
  transform: rotate(-90deg);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_expand-icon-right_16mm3_1axeh_239:not(#\9):dir(rtl) {
  transform: rotate(90deg);
}

.awsui_items-list-container_16mm3_1axeh_247:not(#\9) {
  padding-block: 0;
  padding-inline: 0;
  margin-block-start: -1px;
  margin-block-end: 0;
  margin-inline: 0;
  overflow-y: auto;
}

.awsui_in-dropdown_16mm3_1axeh_256:not(#\9) {
  margin-block-end: -1px;
}