.mio {
  // position: fixed;
  // right: 24px;
  // bottom: 19px;

  & .chat {
    // border: 2px solid #7d8998;
    // border-radius: 12px;
    // padding: 20px;

    & .actions {
      display: none;
      position: absolute !important;
      top: 8px;
      left: 8px;

      & button {
        border: none;
        background-color: transparent;
        color: #0972d3;
        margin-top: 1px;
        padding: 0;
      }
    }

    & .message-out {
      float: right;
      display: flex;
      gap: 10px;

      & .message {
        color: white;
        background-color: #7d8998;
        padding: 8px 12px 10px 12px;
        border-radius: 12px;
        border-bottom-right-radius: 0;
        height: fit-content;
      }
    }

    & .message-in {
      display: flex;
      gap: 10px;

      & .message {
        color: white;
        background-color: #0972d3;
        padding: 8px 12px 10px 12px;
        border-radius: 12px;
        border-bottom-left-radius: 0;
        height: fit-content;
      }
    }

    & .thumbs {
      float: right;

      & button {
        padding: 0 !important;
        margin-right: 10px;
        white-space: nowrap;
        border: none;
        background-color: transparent;
        color: #0972d3;

        &:disabled {
          color: #7d8998;
        }
      }
    }

    .suggestions {
      display: flex;
      flex-direction: row;
      color: #7d8998;

      span {
        margin-right: 10px;
      }

      button {
        padding: 0 !important;
        margin-right: 10px;
        white-space: nowrap;
        border: none;
        background-color: transparent;
        color: #0972d3;
        position: relative;
        padding: 0 10px;
        font-size: 14px;

        &::before {
          content: "\"";
        }

        &::after {
          content: "\"";
        }

        &:not(:last-child) {
        padding-right: 0px;
        }
      }
    }

    .typing {
      // animation: slideIn 0.3s ease-in-out;
      padding: 15px 0;
      // padding: 20px;

      .typing__dot {
        float: right;
        width: 8px;
        height: 8px;
        margin: 0 4px;
        background: #8d8c91;
        border-radius: 50%;
        opacity: 0;
        animation: loadingFade 1s infinite;
      }
      .typing__dot:nth-child(1) {
        animation-delay: 0s;
      }
      .typing__dot:nth-child(2) {
        animation-delay: 0.2s;
      }
      .typing__dot:nth-child(3) {
        animation-delay: 0.4s;
      }
      @keyframes loadingFade {
        0% {
          opacity: 0;
        }

        50% {
          opacity: 0.8;
        }

        100% {
          opacity: 0;
        }
      }
    }
  }

  & .avatar {
    margin-top: 12px;
    text-align: right;
  }

  & .input {
    margin-top: 20px;
    display: flex;
    vertical-align: top;
    font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
    font-size: 14px;

    & textarea {
      border-radius: 12px;
      border: 2px solid #7d8998;
      padding: 12px 12px 0 12px;
      resize: none;
      flex: 1;
      display: block;
      // line-height: 22px;
      border-radius: 8px;
      // background-color: aqua;
      // outline-width: 0;
    }

    & button {
      border: none;
      background-color: transparent;
      color: #0972d3;
      margin-top: 1px;
    }

    ::-webkit-input-placeholder {
      font-style: italic;
    }

    :-moz-placeholder {
      font-style: italic;
    }

    ::-moz-placeholder {
      font-style: italic;
    }

    :-ms-input-placeholder {
      font-style: italic;
    }
  }
}

