/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_root_2rhyz_1p3b2_141:not(#\9) {
  /* used in test-utils for component to distinguish input from other input-like components, for example autosuggest */
}

.awsui_input_2rhyz_1p3b2_145:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  padding-block: var(--space-scaled-xxs-7597g1, 4px);
  padding-inline: var(--space-field-horizontal-gg19kw, 12px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  inline-size: 100%;
  cursor: text;
  box-sizing: border-box;
  background-color: var(--color-background-input-default-u56ls1, #ffffff);
  border-start-start-radius: var(--border-radius-input-plgbrq, 8px);
  border-start-end-radius: var(--border-radius-input-plgbrq, 8px);
  border-end-start-radius: var(--border-radius-input-plgbrq, 8px);
  border-end-end-radius: var(--border-radius-input-plgbrq, 8px);
  border-block: var(--border-field-width-09w7vk, 2px) solid var(--color-border-input-default-l7v83d, #8c8c94);
  border-inline: var(--border-field-width-09w7vk, 2px) solid var(--color-border-input-default-l7v83d, #8c8c94);
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  block-size: var(--size-vertical-input-v5iwwf, 32px);
}
.awsui_input_2rhyz_1p3b2_145.awsui_input-readonly_2rhyz_1p3b2_195:not(#\9) {
  background-color: var(--color-background-input-default-u56ls1, #ffffff);
  border-block: var(--border-field-width-09w7vk, 2px) solid var(--color-border-input-disabled-lb7zn3, #ebebf0);
  border-inline: var(--border-field-width-09w7vk, 2px) solid var(--color-border-input-disabled-lb7zn3, #ebebf0);
}
.awsui_input_2rhyz_1p3b2_145:not(#\9)::-webkit-input-placeholder {
  color: var(--color-text-input-placeholder-f3x213, #656871);
  font-style: italic;
  -webkit-user-select: none;
          user-select: none;
}
.awsui_input_2rhyz_1p3b2_145:not(#\9)::-ms-input-placeholder {
  color: var(--color-text-input-placeholder-f3x213, #656871);
  font-style: italic;
}
.awsui_input_2rhyz_1p3b2_145:not(#\9)::-moz-placeholder {
  color: var(--color-text-input-placeholder-f3x213, #656871);
  font-style: italic;
  opacity: 1;
}
.awsui_input_2rhyz_1p3b2_145:not(#\9):-ms-input-placeholder {
  color: var(--color-text-input-placeholder-f3x213, #656871);
  font-style: italic;
}
.awsui_input_2rhyz_1p3b2_145:not(#\9):-moz-placeholder {
  color: var(--color-text-input-placeholder-f3x213, #656871);
  font-style: italic;
  opacity: 1;
}
.awsui_input_2rhyz_1p3b2_145:not(#\9):focus {
  outline: 2px dotted transparent;
  border-block: var(--border-field-width-09w7vk, 2px) solid var(--color-border-input-focused-c6w5i5, #002b66);
  border-inline: var(--border-field-width-09w7vk, 2px) solid var(--color-border-input-focused-c6w5i5, #002b66);
  border-start-start-radius: var(--border-radius-input-plgbrq, 8px);
  border-start-end-radius: var(--border-radius-input-plgbrq, 8px);
  border-end-start-radius: var(--border-radius-input-plgbrq, 8px);
  border-end-end-radius: var(--border-radius-input-plgbrq, 8px);
  box-shadow: 0 0 0 var(--border-control-focus-ring-shadow-spread-0ctpjf, 0px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_input_2rhyz_1p3b2_145:not(#\9):disabled {
  background-color: var(--color-background-input-disabled-gvxsk4, #ebebf0);
  border-block: var(--border-field-width-09w7vk, 2px) solid var(--color-border-input-disabled-lb7zn3, #ebebf0);
  border-inline: var(--border-field-width-09w7vk, 2px) solid var(--color-border-input-disabled-lb7zn3, #ebebf0);
  color: var(--color-text-input-disabled-fc4jfl, #b4b4bb);
  cursor: auto;
  cursor: default;
}
.awsui_input_2rhyz_1p3b2_145:not(#\9):disabled::-webkit-input-placeholder {
  color: var(--color-text-input-placeholder-disabled-7v2f7f, #b4b4bb);
  -webkit-user-select: none;
          user-select: none;
}
.awsui_input_2rhyz_1p3b2_145:not(#\9):disabled::-ms-input-placeholder {
  color: var(--color-text-input-placeholder-disabled-7v2f7f, #b4b4bb);
}
.awsui_input_2rhyz_1p3b2_145:not(#\9):disabled::-moz-placeholder {
  color: var(--color-text-input-placeholder-disabled-7v2f7f, #b4b4bb);
  opacity: 1;
}
.awsui_input_2rhyz_1p3b2_145:not(#\9):disabled:-ms-input-placeholder {
  color: var(--color-text-input-placeholder-disabled-7v2f7f, #b4b4bb);
}
.awsui_input_2rhyz_1p3b2_145:not(#\9):disabled:-moz-placeholder {
  color: var(--color-text-input-placeholder-disabled-7v2f7f, #b4b4bb);
  opacity: 1;
}
.awsui_input_2rhyz_1p3b2_145:not(#\9):invalid {
  box-shadow: none;
}
.awsui_input_2rhyz_1p3b2_145.awsui_input-invalid_2rhyz_1p3b2_262:not(#\9) {
  color: var(--color-text-status-error-5676bj, #db0000);
  border-color: var(--color-text-status-error-5676bj, #db0000);
  padding-inline-start: calc(var(--space-field-horizontal-gg19kw, 12px) - (var(--border-invalid-width-5ktjp2, 8px) - var(--border-field-width-09w7vk, 2px)));
  border-inline-start-width: var(--border-invalid-width-5ktjp2, 8px);
}
.awsui_input_2rhyz_1p3b2_145.awsui_input-invalid_2rhyz_1p3b2_262:not(#\9):focus {
  box-shadow: 0 0 0 var(--border-control-invalid-focus-ring-shadow-spread-fhmbjn, 2px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_input_2rhyz_1p3b2_145.awsui_input-invalid_2rhyz_1p3b2_262.awsui_input-has-icon-left_2rhyz_1p3b2_271:not(#\9) {
  padding-inline-start: calc(var(--space-field-icon-offset-csk9vw, 36px) - (var(--border-invalid-width-5ktjp2, 8px) - var(--border-field-width-09w7vk, 2px)));
}
.awsui_input_2rhyz_1p3b2_145.awsui_input-warning_2rhyz_1p3b2_274:not(#\9) {
  color: var(--color-text-status-warning-csaw41, #855900);
  border-color: var(--color-text-status-warning-csaw41, #855900);
  padding-inline-start: calc(var(--space-field-horizontal-gg19kw, 12px) - (var(--border-invalid-width-5ktjp2, 8px) - var(--border-field-width-09w7vk, 2px)));
  border-inline-start-width: var(--border-invalid-width-5ktjp2, 8px);
}
.awsui_input_2rhyz_1p3b2_145.awsui_input-warning_2rhyz_1p3b2_274:not(#\9):focus {
  box-shadow: 0 0 0 var(--border-control-invalid-focus-ring-shadow-spread-fhmbjn, 2px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_input_2rhyz_1p3b2_145.awsui_input-warning_2rhyz_1p3b2_274.awsui_input-has-icon-left_2rhyz_1p3b2_271:not(#\9) {
  padding-inline-start: calc(var(--space-field-icon-offset-csk9vw, 36px) - (var(--border-invalid-width-5ktjp2, 8px) - var(--border-field-width-09w7vk, 2px)));
}
.awsui_input_2rhyz_1p3b2_145.awsui_input-type-search_2rhyz_1p3b2_286:not(#\9) {
  box-sizing: border-box;
  -webkit-appearance: none;
}
.awsui_input_2rhyz_1p3b2_145.awsui_input-type-search_2rhyz_1p3b2_286:not(#\9)::-webkit-search-decoration {
  -webkit-appearance: none;
}
.awsui_input_2rhyz_1p3b2_145.awsui_input-type-search_2rhyz_1p3b2_286:not(#\9)::-webkit-search-cancel-button {
  display: none;
}
.awsui_input_2rhyz_1p3b2_145.awsui_input-has-icon-left_2rhyz_1p3b2_271:not(#\9) {
  padding-inline-start: var(--space-field-icon-offset-csk9vw, 36px);
}
.awsui_input_2rhyz_1p3b2_145.awsui_input-has-icon-right_2rhyz_1p3b2_299:not(#\9) {
  padding-inline-end: var(--space-field-icon-offset-csk9vw, 36px);
}
.awsui_input_2rhyz_1p3b2_145.awsui_input-has-no-border-radius_2rhyz_1p3b2_302:not(#\9) {
  border-start-start-radius: var(--border-radius-dropdown-0dhh79, 8px);
  border-start-end-radius: var(--border-radius-dropdown-0dhh79, 8px);
  border-end-start-radius: var(--border-radius-dropdown-0dhh79, 8px);
  border-end-end-radius: var(--border-radius-dropdown-0dhh79, 8px);
}

.awsui_input-container_2rhyz_1p3b2_309:not(#\9) {
  display: flex;
  position: relative;
}

.awsui_input-icon-left_2rhyz_1p3b2_314:not(#\9) {
  position: absolute;
  pointer-events: none;
  inset-inline-start: var(--space-field-horizontal-gg19kw, 12px);
  inset-block-start: calc(50% - var(--line-height-body-m-30ar75, 20px) / 2);
}

.awsui_input-icon-right_2rhyz_1p3b2_321:not(#\9) {
  position: absolute;
  inset-block-start: calc(50% - var(--line-height-body-m-30ar75, 20px) / 2);
  inset-inline-end: calc(var(--space-field-horizontal-gg19kw, 12px) - var(--space-xxs-p8yyaw, 4px));
}

.awsui_input-button-right_2rhyz_1p3b2_327:not(#\9) {
  /* used in test-utils */
}