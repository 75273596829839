/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_universal-toolbar_1kzri_xgu1m_141:not(#\9) {
  background-color: var(--color-background-layout-panel-content-du0fbw, #ffffff);
  box-sizing: border-box;
  padding-block: 0;
  padding-inline: var(--space-m-udix3p, 16px);
  padding-inline-end: 0;
  position: sticky;
  z-index: 840;
  transition: ease var(--motion-duration-refresh-only-slow-zbvbxt, 250ms);
  transition-property: inset-block-start, opacity;
}
@media (prefers-reduced-motion: reduce) {
  .awsui_universal-toolbar_1kzri_xgu1m_141:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_universal-toolbar_1kzri_xgu1m_141:not(#\9), .awsui-mode-entering .awsui_universal-toolbar_1kzri_xgu1m_141:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_universal-toolbar_1kzri_xgu1m_141.awsui_disable-body-scroll_1kzri_xgu1m_162:not(#\9) {
  inset-block-start: 0px;
}
.awsui_universal-toolbar_1kzri_xgu1m_141.awsui_toolbar-hidden_1kzri_xgu1m_165:not(#\9) {
  opacity: 0;
}
.awsui_universal-toolbar_1kzri_xgu1m_141 > .awsui_toolbar-container_1kzri_xgu1m_168:not(#\9) {
  block-size: 100%;
  align-items: center;
  display: grid;
  column-gap: var(--space-static-xs-7sfb63, 8px);
  inline-size: 100%;
  grid-template-columns: min-content minmax(0, 3fr) minmax(auto, 1fr);
  grid-template-rows: 1fr;
}
.awsui_universal-toolbar_1kzri_xgu1m_141 > .awsui_toolbar-container_1kzri_xgu1m_168 > .awsui_universal-toolbar-nav_1kzri_xgu1m_177:not(#\9) {
  grid-column: 1;
  padding-inline-end: var(--space-static-xxs-82cdfi, 4px);
}
.awsui_universal-toolbar_1kzri_xgu1m_141 > .awsui_toolbar-container_1kzri_xgu1m_168 > .awsui_universal-toolbar-breadcrumbs_1kzri_xgu1m_181:not(#\9) {
  grid-column: 2;
  background-color: transparent;
  flex: 1 0;
}
.awsui_universal-toolbar_1kzri_xgu1m_141 > .awsui_toolbar-container_1kzri_xgu1m_168 > .awsui_universal-toolbar-drawers_1kzri_xgu1m_186:not(#\9) {
  grid-column: 3;
  column-gap: var(--space-static-xs-7sfb63, 8px);
  display: flex;
  justify-content: flex-end;
  block-size: 100%;
}

.awsui_drawers-desktop-triggers-container_1kzri_xgu1m_194:not(#\9),
.awsui_drawers-mobile-triggers-container_1kzri_xgu1m_195:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  background-color: transparent;
  padding-inline: 0;
  padding-inline-end: var(--space-m-udix3p, 16px);
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  overscroll-behavior-y: contain;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  overscroll-behavior-x: contain;
  inline-size: 100%;
}

.awsui_drawers-trigger-content_1kzri_xgu1m_241:not(#\9) {
  block-size: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--space-xs-zb16t3, 8px);
  justify-content: flex-end;
}

.awsui_group-divider_1kzri_xgu1m_250:not(#\9) {
  border-inline-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
  block-size: 60%;
}

@media (max-width: 688px) {
  .awsui_drawers-trigger_1kzri_xgu1m_241:not(#\9) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.awsui_block-body-scroll_1kzri_xgu1m_263:not(#\9) {
  overflow: hidden;
}