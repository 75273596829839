/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_dropdown-content-wrapper_qwoo0_1wzpb_149:not(#\9) {
  animation: awsui_awsui-motion-fade-in-dropdown_qwoo0_1wzpb_1 var(--motion-duration-show-quick-aq3ump, 135ms) var(--motion-easing-show-quick-vc5zgd, ease-out);
}
@keyframes awsui_awsui-motion-fade-in-dropdown_qwoo0_1wzpb_1 {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_dropdown-content-wrapper_qwoo0_1wzpb_149:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_dropdown-content-wrapper_qwoo0_1wzpb_149:not(#\9), .awsui-mode-entering .awsui_dropdown-content-wrapper_qwoo0_1wzpb_149:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_dropdown-content-wrapper_qwoo0_1wzpb_149.awsui_refresh_qwoo0_1wzpb_170:not(#\9) {
  transform-origin: top;
  animation-name: awsui_awsui-motion-fade-in-0_qwoo0_1wzpb_1;
  animation-duration: var(--motion-duration-show-quick-aq3ump, 135ms);
  animation-timing-function: var(--motion-easing-show-quick-vc5zgd, ease-out);
}
@keyframes awsui_awsui-motion-fade-in-0_qwoo0_1wzpb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_dropdown-content-wrapper_qwoo0_1wzpb_149.awsui_refresh_qwoo0_1wzpb_170:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_dropdown-content-wrapper_qwoo0_1wzpb_149.awsui_refresh_qwoo0_1wzpb_170:not(#\9), .awsui-mode-entering .awsui_dropdown-content-wrapper_qwoo0_1wzpb_149.awsui_refresh_qwoo0_1wzpb_170:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_qwoo0_1wzpb_195:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  position: relative;
  white-space: inherit;
}
.awsui_root_qwoo0_1wzpb_195.awsui_interior_qwoo0_1wzpb_231:not(#\9) {
  position: static;
}

.awsui_dropdown_qwoo0_1wzpb_149:not(#\9) {
  position: absolute;
  display: none;
  z-index: 2000;
  -webkit-user-select: none;
          user-select: none;
  transform-origin: top;
}
.awsui_dropdown_qwoo0_1wzpb_149.awsui_use-portal_qwoo0_1wzpb_242:not(#\9) {
  z-index: 7000;
}
.awsui_dropdown_qwoo0_1wzpb_149:not(#\9):not(.awsui_interior_qwoo0_1wzpb_231) {
  transform: translateY(0px);
  transition: transform var(--motion-duration-show-quick-aq3ump, 135ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_dropdown_qwoo0_1wzpb_149:not(#\9):not(.awsui_interior_qwoo0_1wzpb_231) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_dropdown_qwoo0_1wzpb_149:not(#\9):not(.awsui_interior_qwoo0_1wzpb_231), .awsui-mode-entering .awsui_dropdown_qwoo0_1wzpb_149:not(#\9):not(.awsui_interior_qwoo0_1wzpb_231) {
  animation: none;
  transition: none;
}
.awsui_dropdown-content-wrapper_qwoo0_1wzpb_149:not(#\9) {
  position: relative;
  background-color: var(--color-background-dropdown-item-default-u8emhk, #ffffff);
  outline: none;
  box-shadow: var(--shadow-dropdown-e5guvm, 0px 4px 20px 1px rgba(0, 7, 22, 0.1));
  border-start-start-radius: var(--border-radius-dropdown-0dhh79, 8px);
  border-start-end-radius: var(--border-radius-dropdown-0dhh79, 8px);
  border-end-start-radius: var(--border-radius-dropdown-0dhh79, 8px);
  border-end-end-radius: var(--border-radius-dropdown-0dhh79, 8px);
  border-block: var(--border-divider-list-width-27y3k5, 1px) solid var(--color-border-container-top-736buh, transparent);
  box-sizing: border-box;
}
.awsui_dropdown-content-wrapper_qwoo0_1wzpb_149:not(#\9)::after {
  content: "";
  position: absolute;
  z-index: 1;
  inset-block-start: -1px;
  inset-block-end: -1px;
  inset-inline-start: 0px;
  inset-inline-end: 0px;
  background-color: transparent;
  border-block: var(--border-field-width-09w7vk, 2px) solid var(--color-border-dropdown-container-fo7aoz, #b4b4bb);
  border-inline: var(--border-field-width-09w7vk, 2px) solid var(--color-border-dropdown-container-fo7aoz, #b4b4bb);
  border-start-start-radius: var(--border-radius-dropdown-0dhh79, 8px);
  border-start-end-radius: var(--border-radius-dropdown-0dhh79, 8px);
  border-end-start-radius: var(--border-radius-dropdown-0dhh79, 8px);
  border-end-end-radius: var(--border-radius-dropdown-0dhh79, 8px);
  pointer-events: none;
}
.awsui_dropdown-content-wrapper_qwoo0_1wzpb_149.awsui_is-empty_qwoo0_1wzpb_288:not(#\9)::after {
  display: none;
}
.awsui_dropdown_qwoo0_1wzpb_149 > .awsui_dropdown-content-wrapper_qwoo0_1wzpb_149:not(#\9) {
  border-block-start: var(--border-divider-list-width-27y3k5, 1px) solid var(--color-border-container-top-736buh, transparent);
  border-block-end: var(--border-divider-list-width-27y3k5, 1px) solid var(--color-border-dropdown-group-2xoluv, #c6c6cd);
}
.awsui_dropdown-drop-up_qwoo0_1wzpb_295 > .awsui_dropdown-content-wrapper_qwoo0_1wzpb_149:not(#\9) {
  transform-origin: bottom;
  box-shadow: var(--shadow-dropup-xqkiza, 0px 4px 20px 1px rgba(0, 7, 22, 0.1));
}
.awsui_dropdown-drop-up_qwoo0_1wzpb_295.awsui_with-limited-width_qwoo0_1wzpb_299 > .awsui_dropdown-content-wrapper_qwoo0_1wzpb_149:not(#\9) {
  border-block-start: none;
}
.awsui_dropdown-drop-left_qwoo0_1wzpb_302:not(#\9) {
  inset-inline-end: 0;
}
.awsui_dropdown-drop-right_qwoo0_1wzpb_305:not(#\9) {
  inset-inline-start: 0;
}
.awsui_dropdown_qwoo0_1wzpb_149.awsui_occupy-entire-width_qwoo0_1wzpb_308:not(#\9) {
  min-inline-size: 100%;
}
.awsui_dropdown_qwoo0_1wzpb_149.awsui_stretch-beyond-trigger-width_qwoo0_1wzpb_311:not(#\9) {
  inline-size: max-content;
  max-inline-size: var(--awsui-dropdown-default-max-width-g964ok, 100%);
}
.awsui_dropdown_qwoo0_1wzpb_149.awsui_interior_qwoo0_1wzpb_231 > .awsui_dropdown-content-wrapper_qwoo0_1wzpb_149:not(#\9) {
  margin-block-start: -1px;
}
.awsui_dropdown_qwoo0_1wzpb_149.awsui_hide-block-border_qwoo0_1wzpb_318 > .awsui_dropdown-content-wrapper_qwoo0_1wzpb_149:not(#\9) {
  border-block-start: none;
}
.awsui_dropdown_qwoo0_1wzpb_149.awsui_hide-block-border_qwoo0_1wzpb_318:not(#\9):not(.awsui_dropdown-drop-up_qwoo0_1wzpb_295) > .awsui_dropdown-content-wrapper_qwoo0_1wzpb_149 {
  border-block-end: none;
}
.awsui_dropdown_qwoo0_1wzpb_149.awsui_hide-block-border_qwoo0_1wzpb_318.awsui_refresh_qwoo0_1wzpb_170 > .awsui_dropdown-content-wrapper_qwoo0_1wzpb_149:not(#\9) {
  border-block-end: none;
}
.awsui_dropdown_qwoo0_1wzpb_149.awsui_open_qwoo0_1wzpb_327:not(#\9) {
  display: block;
}
.awsui_dropdown_qwoo0_1wzpb_149.awsui_open_qwoo0_1wzpb_327:not(#\9):not(.awsui_interior_qwoo0_1wzpb_231).awsui_refresh_qwoo0_1wzpb_170[data-animating=true] {
  transform: translateY(4px);
}
.awsui_dropdown_qwoo0_1wzpb_149.awsui_open_qwoo0_1wzpb_327:not(#\9):not(.awsui_interior_qwoo0_1wzpb_231).awsui_refresh_qwoo0_1wzpb_170[data-animating=true].awsui_dropdown-drop-up_qwoo0_1wzpb_295 {
  transform: translateY(-4px);
}
.awsui_dropdown_qwoo0_1wzpb_149.awsui_open_qwoo0_1wzpb_327.awsui_nowrap_qwoo0_1wzpb_336:not(#\9) {
  white-space: nowrap;
  overflow: scroll;
}

.awsui_dropdown-content_qwoo0_1wzpb_149:not(#\9) {
  display: flex;
  flex-direction: column;
  inline-size: 100%;
}

.awsui_stretch-trigger-height_qwoo0_1wzpb_347:not(#\9) {
  block-size: 100%;
}