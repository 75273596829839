/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_trigger-button-styles_lpshu_1jpng_141:not(#\9) {
  background: transparent;
  color: var(--color-text-interactive-default-tkx8fe, #424650);
  border-start-start-radius: 50%;
  border-start-end-radius: 50%;
  border-end-start-radius: 50%;
  border-end-end-radius: 50%;
  block-size: 30px;
  inline-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.awsui_trigger-button-styles_lpshu_1jpng_141:not(#\9):hover {
  background: var(--color-background-input-disabled-gvxsk4, #ebebf0);
  color: var(--color-text-interactive-hover-f9gqs8, #0f141a);
}
.awsui_trigger-button-styles_lpshu_1jpng_141:not(#\9):active {
  background: var(--color-background-control-disabled-5cbvij, #dedee3);
  color: var(--color-text-interactive-hover-f9gqs8, #0f141a);
}

.awsui_trigger-badge-wrapper_lpshu_1jpng_164:not(#\9) {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: path("M29.2862 10.4145C28.7243 10.5998 28.1238 10.7 27.4999 10.7C24.3519 10.7 21.7999 8.14803 21.7999 5C21.7999 3.92883 22.0954 2.92667 22.6093 2.07057C20.3785 0.754846 17.7774 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 13.4007 29.7497 11.8599 29.2862 10.4145Z");
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_trigger-badge-wrapper_lpshu_1jpng_164:not(#\9):dir(rtl) {
  clip-path: path("M2.21384 10.4145C2.77569 10.5998 3.37617 10.7 4.00007 10.7C7.1481 10.7 9.70007 8.14803 9.70007 5C9.70007 3.92883 9.4046 2.92667 8.89071 2.07057C11.1215 0.754846 13.7226 0 16.5 0C24.7843 0 31.5 6.71573 31.5 15C31.5 23.2843 24.7843 30 16.5 30C8.21573 30 1.5 23.2843 1.5 15C1.5 13.4007 1.75029 11.8599 2.21384 10.4145Z");
}

.awsui_trigger_lpshu_1jpng_141:not(#\9) {
  border-block: none;
  border-inline: none;
  cursor: pointer;
  pointer-events: auto;
}
body[data-awsui-focus-visible=true] .awsui_trigger_lpshu_1jpng_141:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_trigger_lpshu_1jpng_141:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(3px - 1px);
}
body[data-awsui-focus-visible=true] .awsui_trigger_lpshu_1jpng_141:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * 3px);
  inset-block-start: calc(-1 * 3px);
  inline-size: calc(100% + 3px + 3px);
  block-size: calc(100% + 3px + 3px);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_trigger_lpshu_1jpng_141:not(#\9):focus {
  outline: none;
}
.awsui_trigger_lpshu_1jpng_141.awsui_selected_lpshu_1jpng_208:not(#\9) {
  background: var(--color-background-layout-toggle-selected-default-27rnk6, #006ce0);
  color: var(--color-text-layout-toggle-selected-kb3p9r, #ffffff);
}
.awsui_trigger_lpshu_1jpng_141.awsui_selected_lpshu_1jpng_208:not(#\9):hover {
  background: var(--color-background-layout-toggle-selected-hover-eh8bga, #004a9e);
}
.awsui_trigger_lpshu_1jpng_141.awsui_selected_lpshu_1jpng_208:not(#\9):active {
  background: var(--color-background-layout-toggle-selected-active-tv8i8b, #006ce0);
}
.awsui_trigger_lpshu_1jpng_141.awsui_selected_lpshu_1jpng_208 > .awsui_trigger-badge-wrapper_lpshu_1jpng_164:not(#\9) {
  background: var(--color-background-layout-toggle-selected-default-27rnk6, #006ce0);
  color: var(--color-text-layout-toggle-selected-kb3p9r, #ffffff);
}
.awsui_trigger_lpshu_1jpng_141.awsui_selected_lpshu_1jpng_208 > .awsui_trigger-badge-wrapper_lpshu_1jpng_164:not(#\9):hover {
  background: var(--color-background-layout-toggle-selected-hover-eh8bga, #004a9e);
}
.awsui_trigger_lpshu_1jpng_141.awsui_selected_lpshu_1jpng_208 > .awsui_trigger-badge-wrapper_lpshu_1jpng_164:not(#\9):active {
  background: var(--color-background-layout-toggle-selected-active-tv8i8b, #006ce0);
}
.awsui_trigger_lpshu_1jpng_141.awsui_badge_lpshu_1jpng_228:not(#\9), .awsui_trigger_lpshu_1jpng_141.awsui_badge_lpshu_1jpng_228:not(#\9):hover, .awsui_trigger_lpshu_1jpng_141.awsui_badge_lpshu_1jpng_228:not(#\9):active {
  background: transparent;
}

.awsui_trigger-wrapper_lpshu_1jpng_232:not(#\9) {
  position: relative;
  border-start-start-radius: 50%;
  border-start-end-radius: 50%;
  border-end-start-radius: 50%;
  border-end-end-radius: 50%;
}

.awsui_trigger-wrapper-tooltip-visible_lpshu_1jpng_240:not(#\9) {
  /* used in test-utils*/
}

.awsui_dot_lpshu_1jpng_244:not(#\9) {
  position: absolute;
  inline-size: 8px;
  block-size: 8px;
  border-start-start-radius: 8px;
  border-start-end-radius: 8px;
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
  background-color: var(--color-background-badge-icon-n0lc3o, #db0000);
  inset-block-start: 1px;
  inset-inline-end: -1px;
}

.awsui_trigger-tooltip_lpshu_1jpng_257:not(#\9) {
  /* used in test-utils */
}